export default {

	init() {

		var container = document.querySelector('.ArticleSectionItems');

		if( container ) {

			var items = container.querySelectorAll('.left__items .items__item');
			var slides = container.querySelectorAll('.right__slides .slides__slide');
			var slideDefault = container.querySelector('.right__slides .slides__default');

			items.forEach( function( item, index ) {

				item.addEventListener('mouseenter', function(e) {

					e.stopPropagation();

					if( 
						window.innerWidth > 1080 &&
						!container.querySelector('.right__slides .slides__slide.activePermanent')
					) {
						
						slideDefault.classList.remove('active');
							
						slides.forEach( function( slide, index2 ) {

							slide.classList.remove('active');

						});

						slides[index].classList.add('active');

					}

				});

				item.addEventListener('mouseleave', function(e) {

					if( window.innerWidth > 1080 ) {

						slides.forEach( function( slide,index2 ) {

							slide.classList.remove('active');

						});

						if( !container.querySelector('.right__slides .slides__slide.activePermanent')  ) {

							slideDefault.classList.add('active');

						}

					}

				});

				item.addEventListener('click', function(e) {

					e.stopPropagation();

					if( window.innerWidth <= 1080 ) {

						if( item.classList.contains('active') ) {

							item.classList.remove('active');
							item.querySelector('.item__layout--space').style.height = '0px';

						} else {

							items.forEach( function( item2, index2 ) {
	 	
								item2.classList.remove('active');
								item2.querySelector('.item__layout--space').style.height = '0px';

							});

							item.classList.add('active');							
							item.querySelector('.item__layout--space').style.height = (item.querySelector('.item__layout').offsetHeight+4)+'px';

						}

					} else {

						if( !slides[index].classList.contains('activePermanent') ) {

							slideDefault.classList.remove('active');

							slides.forEach( function( slide, index2 ) {

								slide.classList.remove('activePermanent');

							});

							slides[index].classList.add('activePermanent');

						} else {

							slides.forEach( function( slide, index2 ) {

								slide.classList.remove('activePermanent');

							});

							slideDefault.classList.add('active');

						}

					}

				});

				item.querySelector('.item__layout').addEventListener('click', function(e) {

					e.stopPropagation();

				});

			});

			slides.forEach( function( slide,index ) {

				slide.addEventListener('click', function() {

					if( slide.classList.contains('activePermanent') ) {

						slide.classList.remove('activePermanent');
						slide.classList.remove('active');
						slideDefault.classList.add('active');

					}

				});

				slide.querySelector('.slide__content').addEventListener('click', function(e) {

					e.stopPropagation();

				});

			});

			window.addEventListener('resize', function() {

				if( window.innerWidth <= 1080 ) {

					slides.forEach( function( slide,item ) {

						slide.classList.remove('active');
						slide.classList.remove('activePermanent');

					});

					slideDefault.classList.add('active');

				} else {

					items.forEach( function( item, index ) {

						item.classList.remove('active');

					});				

				}

			});

			document.addEventListener('click', function() {

				if( window.innerWidth <= 1080 ) {

					items.forEach( function(item,index) {

						item.classList.remove('active');

					});

				} else {

					slides.forEach( function( slide,item ) {

						slide.classList.remove('active');
						slide.classList.remove('activePermanent');

					});

					slideDefault.classList.add('active');
					
				}

			});

		}

	},
	finalize() {

	}

}