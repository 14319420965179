// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import test from '../src/Pages/Test/Test';
import projectarchive from '../src/Pages/ProjectArchive/ProjectArchive';


LazyLoad.init();
Subpage.init();
Subpage.cursors();

const routes = new Router({
	test,
	homepage,
	projectarchive,
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});