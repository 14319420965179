import 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import { DDSLoader } from "three/examples/jsm/loaders/DDSLoader"
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"


export default {

	init() {

		var container;

		var camera, scene, renderer;

		var mouseX = 0, mouseY = 0;

		var windowHalfX = window.innerWidth / 2;
		var windowHalfY = window.innerHeight / 2;

		var object;

		init();
		


		function init() {

			container = document.createElement( 'div' );
			document.body.appendChild( container );

			camera = new THREE.PerspectiveCamera( 7, window.innerWidth / window.innerHeight, 1, 2000 );
			camera.position.z = 20;

			// scene

			scene = new THREE.Scene();
			scene.background = new THREE.Color( 0xffffff );

			var ambientLight = new THREE.AmbientLight( 0xffffff, 0.8 );
			scene.add( ambientLight );

			var pointLight = new THREE.PointLight( 0xffffff, 0.3, 2000, 2 );
			camera.add( pointLight );
			scene.add( camera );

			// manager

			function loadModel() {

				object.traverse( function ( child ) {

					if ( child.isMesh ) {
						child.material.map = texture;
						child.material.transparent = true;
						child.material.opacity = 1;
					}

				} );

				object.position.y = 0;
				scene.add( object );

				animate();

			}

			var manager = new THREE.LoadingManager( loadModel );

			manager.onProgress = function ( item, loaded, total ) {

				console.log( item, loaded, total );

			};

			// texture

			var textureLoader = new THREE.TextureLoader( manager );

			var texture = textureLoader.load( 'http://localhost:9450/wp-content/themes/grinspire/assets/obj/plazma/plazma.png' );

			// model

			function onProgress( xhr ) {

				if ( xhr.lengthComputable ) {

					var percentComplete = xhr.loaded / xhr.total * 100;
					console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );

				}

			}

			function onError() {}

			var loader = new OBJLoader( manager );

			loader.load( 'http://localhost:9450/wp-content/themes/grinspire/assets/obj/plazma/plazma.obj', function ( obj ) {
			//loader.load( 'http://localhost:9450/wp-content/themes/grinspire/assets/obj/male02/male02.obj', function ( obj ) {


				object = obj;


			}, onProgress, onError );

			//

			renderer = new THREE.WebGLRenderer({ antialias: true });
			renderer.setPixelRatio( window.devicePixelRatio );
			renderer.setSize( window.innerWidth, window.innerHeight );
			container.appendChild( renderer.domElement );

			document.addEventListener( 'mousemove', onDocumentMouseMove, false );

			//

			window.addEventListener( 'resize', onWindowResize, false );

		}

		function onWindowResize() {

			windowHalfX = window.innerWidth / 2;
			windowHalfY = window.innerHeight / 2;

			camera.aspect = window.innerWidth / window.innerHeight;
			camera.updateProjectionMatrix();

			renderer.setSize( window.innerWidth, window.innerHeight );

		}

		function onDocumentMouseMove( event ) {

			mouseX = ( event.clientX - windowHalfX ) / 200;
			mouseY = ( event.clientY - windowHalfY ) / 200;

		}

		//

		function animate() {

			object.rotation.x += 0.002;
          	object.rotation.y += 0.004;

          	//console.log(object);

			requestAnimationFrame( animate );
			render();

		}

		function render() {

			camera.position.x += ( mouseX - camera.position.x ) * .05;
			camera.position.y += ( - mouseY - camera.position.y ) * .05;

			camera.lookAt( scene.position );

			renderer.render( scene, camera );

		}







		// var container;

		// var camera, scene, renderer;

		// var mouseX = 0, mouseY = 0;

		// var windowHalfX = window.innerWidth / 2;
		// var windowHalfY = window.innerHeight / 2;


		// init();
		// animate();


		// function init() {

		// 	container = document.createElement( 'div' );
		// 	document.body.appendChild( container );

		// 	camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2000 );
		// 	camera.position.z = 250;

		// 	// scene

		// 	scene = new THREE.Scene();
		// 	scene.background = new THREE.Color( 0xffffff );

		// 	var ambientLight = new THREE.AmbientLight( 0xffffff );
		// 	scene.add( ambientLight );

		// 	// var light = new THREE.PointLight( 0xffffff, 1, 5000, 2 );
		// 	// light.position.set( 50, 50, 50 );
		// 	// scene.add( light );

		// 	var light = new THREE.PointLight( 0xffffff, 1, 2250 );
		// 		camera.add( light );


		// 	scene.add( camera );

		// 	// model

		// 	var onProgress = function ( xhr ) {

		// 		if ( xhr.lengthComputable ) {

		// 			var percentComplete = xhr.loaded / xhr.total * 100;
		// 			console.log( Math.round( percentComplete, 2 ) + '% downloaded' );

		// 		}

		// 	};

		// 	var onError = function () { };

		// 	var manager = new THREE.LoadingManager();
		// 	manager.addHandler( /\.dds$/i, new DDSLoader() );

		// 	// comment in the following line and import TGALoader if your asset uses TGA textures
		// 	// manager.addHandler( /\.tga$/i, new TGALoader() );

		// 	new MTLLoader( manager )
		// 		.setPath( 'http://localhost:9450/wp-content/themes/grinspire/assets/obj/male02/' )
		// 		.load( 'male02_dds.mtl', function ( materials ) {

		// 			materials.preload();

		// 			new OBJLoader( manager )
		// 				.setMaterials( materials )
		// 				.setPath( 'http://localhost:9450/wp-content/themes/grinspire/assets/obj/male02/' )
		// 				.load( 'male02.obj', function ( object ) {

		// 					object.position.y = - 95;
		// 					scene.add( object );

		// 				}, onProgress, onError );

		// 		} );

		// 	//

		// 	renderer = new THREE.WebGLRenderer();
		// 	renderer.setPixelRatio( window.devicePixelRatio );
		// 	renderer.setSize( window.innerWidth, window.innerHeight );
		// 	container.appendChild( renderer.domElement );

		// 	document.addEventListener( 'mousemove', onDocumentMouseMove, false );

		// 	//

		// 	window.addEventListener( 'resize', onWindowResize, false );

		// }

		// function onWindowResize() {

		// 	windowHalfX = window.innerWidth / 2;
		// 	windowHalfY = window.innerHeight / 2;

		// 	camera.aspect = window.innerWidth / window.innerHeight;
		// 	camera.updateProjectionMatrix();

		// 	renderer.setSize( window.innerWidth, window.innerHeight );

		// }

		// function onDocumentMouseMove( event ) {

		// 	mouseX = ( event.clientX - windowHalfX ) / 2;
		// 	mouseY = ( event.clientY - windowHalfY ) / 2;

		// }

		// //

		// function animate() {

		// 	requestAnimationFrame( animate );
		// 	render();

		// }

		// function render() {

		// 	camera.position.x += ( mouseX - camera.position.x ) * .05;
		// 	camera.position.y += ( - mouseY - camera.position.y ) * .05;

		// 	camera.lookAt( scene.position );

		// 	renderer.render( scene, camera );

		// }


	},
	finalize() {

	}

}