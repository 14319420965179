import $ from 'jquery';
import tilt from 'tilt.js/src/tilt.jquery.js';

export default {

	init() {

		var container = document.querySelector('.HomeHead');

		if( container ) {

			function homeHeadScroll() {

				var section2 = document.querySelectorAll('section')[1];
				var header = document.querySelector('header').offsetHeight;

				container.addEventListener('click', function() {

					$("html, body").animate({ scrollTop: section2.offsetTop }, 600);

				}); 

			}
			homeHeadScroll();

			
			function homeHeadAnimationSVG(){

				var svg1 = container.querySelector('.homeHead--svg1 svg');
				var svg2 = container.querySelector('.homeHead--svg2 svg');

				$(svg1).tilt({
					maxTilt: 30,
					reset: false,
					speed: 600,
					perspective: 800,
				});

				$(svg2).tilt({
					maxTilt: 30,
					reset: false,
					speed: 600,
					perspective: 400,
				});

			}
			homeHeadAnimationSVG();		

			
			function homeHeadMoveSVG() {

				var svg1 = container.querySelector('.homeHead--svg1');
				var svg2 = container.querySelector('.homeHead--svg2');

				var svg1center = svg1.offsetWidth/2;
				var svg2center = svg2.offsetWidth/2;

				var containerHeight = container.offsetHeight;
				var containerWidth = container.offsetWidth;

				function setStartPosition( svg, x = 'left' , y = 'top' ) {

					var svgSize = svg.offsetWidth/2;
					svg.style.top = (containerHeight/2) - svgSize+'px';
					svg.style.left = (containerWidth/2) - svgSize+'px';

					new newCoordinate( svg, x, y );

				}

				function newCoordinate( svg, x = 'left', y = 'top' ) {

					var size = svg.offsetWidth;
					var objectStyle = getComputedStyle(svg);
					var objectTop = parseFloat(objectStyle.top.replace('px','') );
					var objectLeft = parseFloat(objectStyle.left.replace('px','') );

					var leftMin = -size/2;
					var leftMax = containerWidth - (size/2);

					var topMin = -size/2;
					var topMax = containerHeight - (size/2);

					var timer = Math.random() * (12000 - 8000) + 8000;
					var xNew = x;
					var yNew = y;
					var leftNew = objectLeft;
					var topNew = 0;
					var rotateNew = Math.random() * (180 - (-180) ) - 180;	


					// -- generate X -- //

						if( x == 'left' ) {

							if( objectLeft <= 200 ) { 

								leftNew = leftMin;

							} else {

								objectLeft -= 200;
								leftNew = Math.floor( Math.random() * (objectLeft - leftMin) ) + leftMin; 

							}

						} else {

							if( leftMax - objectLeft <= 200 ) {

								leftNew = leftMax;

							} else if( leftMax - objectLeft > 200 ) {

								objectLeft += 200;

								leftNew = Math.floor( Math.random() * (leftMax + 1 - objectLeft) ) + objectLeft; 							

							}

						}

					// -- END -- // 



					// -- Set Y & X direction -- //

						if( leftNew == leftMax ) {

							xNew = 'left';

							yNew = y;

							if( y == 'bottom' ) {

								topNew = Math.floor( Math.random() * (topMax - objectTop) ) + objectTop;

							} else {

								topNew = Math.floor( Math.random() * ( objectTop - topMin ) ) + topMin;

							}

						} else if( leftNew == leftMin ) {

							xNew = 'right';

							yNew = y;

							if( y == 'bottom' ) {

								topNew = Math.floor( Math.random() * ( topMax - objectTop ) ) + objectTop;

							} else {

								topNew = Math.floor( Math.random() * ( objectTop - topMin ) ) + topMin;

							}

						} else {

							if( y == 'top' ) {

								yNew = 'bottom';
								topNew = topMin;

							} else {

								yNew = 'top';
								topNew = topMax;

							}

						}

					// -- END -- //


					// console.log( svg );
					// console.log( xNew );
					// console.log( leftNew );
					// console.log( yNew );
					// console.log( topNew );

					
					svg.style.transitionDuration = timer+'ms';
					svg.style.left = leftNew+'px';
					svg.style.top = topNew+'px';
					svg.style.transform = 'rotate('+rotateNew+'deg)';

					setTimeout(
						function() {

							new newCoordinate( svg, xNew, yNew );	

						},
						timer
					);

				}

				window.addEventListener('resize', function() {

					containerHeight = container.offsetHeight;
					containerWidth = container.offsetWidth;

				});

				new setStartPosition( svg1, 'right', 'top' );
				new setStartPosition( svg2, 'left' , 'bottom' );

				setTimeout(
					function() {

						svg1.classList.add('active');
						svg2.classList.add('active');

					},500
				);

			}
			homeHeadMoveSVG();

		}

	},
	finalize() {

	}

}