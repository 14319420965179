import Subpage from '../../Layout/Subpage';
import lozad from 'lozad';
import LazyLoad from '../../../common/scripts/lazyLoad';

export default {

	init() {

		var container = document.querySelector('.ProjectArchive--main');

		if( container ) {

			var catGroup = container.querySelector('.cat__group');
			var catButtons = catGroup.querySelectorAll('button.cat__button--cat');
			var buttonList = container.querySelector('.cat__button--mobile');

			var xhr = new XMLHttpRequest();
			var xmlDoc = null;
			function getContent( slug ) {

				container.classList.add('disable');

				xhr = new XMLHttpRequest();
				xhr.onreadystatechange = function() {
					
					if( xhr.readyState == 4 && xhr.status == 200) {

            			var xmlDoc = document.implementation.createHTMLDocument("example");
						xmlDoc.documentElement.innerHTML = xhr.responseText;

						container.querySelectorAll('.projectArchive__list .list__row').forEach( function( box,index ) {

							box.remove();

						});

						xmlDoc.querySelectorAll('.projectArchive__list .list__row').forEach( function( box,index ) {

							container.querySelector('.projectArchive__list').insertBefore(box, container.querySelector('.projectArchive__list').lastChild.nextSibling );

						});

						LazyLoad.init();

						container.classList.remove('disable');

						Subpage.cursors();

        			} 

				}	
				
				xhr.open( 'GET', window.location.href+'?ajax=yes&slug='+slug, true );
				xhr.send();

			}

			catButtons.forEach( function( button, index ) {

				button.addEventListener('click', function() {

					catButtons.forEach( function( button2, index2 ) {

						button2.classList.remove('active');

					});

					getContent( button.getAttribute('data-slug') );
					button.classList.add('active');
						
				});

			});


			buttonList.addEventListener('click', function() {

				if( catGroup.classList.contains('active') ) {

					buttonList.classList.remove('open');
					catGroup.classList.remove('active');

				} else {

					catGroup.classList.add('active');
					buttonList.classList.add('open');

				}

			});

		}

	},
	finalize() {

	}

}