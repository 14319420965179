import ArticleSectionItems from '../../Sections/ArticleSectionItems/ArticleSectionItems';
import Projects from '../../Sections/Projects/Projects';
import Quotations from '../../Sections/Quotations/Quotations';
import HomeHead from '../../Sections/HomeHead/HomeHead';
import HomeHead2 from '../../Sections/HomeHead2/HomeHead2';
	
export default {
	init() {

		ArticleSectionItems.init();
		Projects.init();
		Quotations.init();
		HomeHead.init();
		HomeHead2.init();
    	
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
