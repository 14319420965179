import 'three';
import $ from 'jquery';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import { DDSLoader } from "three/examples/jsm/loaders/DDSLoader"
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"


export default {

	init() {

		var section = document.querySelector('.HomeHead2');

		var container;

		var camera, scene, renderer;

		var mouseX = 0, mouseY = 0;

		var windowHalfX = window.innerWidth / 2;
		var windowHalfY = window.innerHeight / 2;

		var object;

		init();
		


		function init() {

			container = document.createElement( 'div' );
			container.setAttribute("class", "object3d");
			section.appendChild( container );

			camera = new THREE.PerspectiveCamera( 7, window.innerWidth / window.innerHeight, 1, 1000 );
			camera.position.z = 20;

			// scene

			scene = new THREE.Scene();
			scene.background = new THREE.Color( 0xffffff );

			var ambientLight = new THREE.AmbientLight( 0xffffff, 0.8 );
			ambientLight.position.set( 250, 250, 250 );
			scene.add( ambientLight );

			var pointLight = new THREE.PointLight( 0xffffff, 0.26, 2000, 2 );
			pointLight.position.set( 20, 20, 120 );
			camera.add( pointLight );
			scene.add( camera );


			// manager

			function loadModel() {

				object.traverse( function ( child ) {

					var material = new THREE.ShaderMaterial({
					  uniforms: {
					    color1: {
					      value: new THREE.Color(0x8cc63f)
					    },
					    color2: {
					      value: new THREE.Color(0x78af2f)
					    },
					  },
					  vertexShader: `
					    varying vec2 vUv;

					    void main() {
							vUv = uv;
							gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
					    }
					  `,
					  fragmentShader: `
					    uniform vec3 color1;
					    uniform vec3 color2;
					  
					    varying vec2 vUv;
					    
					    void main() {
					      
							gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);

					    }
					  `,
					});					
					child.material = material;
					child.material.transparent = true;
					child.material.opacity = 0.9;

				} );

				// object.position.y = -0.25;
				object.position.y = 0;
				// object.position.x = 0.8;
				object.position.x = 1;
				scene.add( object );

				animate();
				section.querySelector('.homeHead2--mask').classList.add('disable'); // disable mask

			}

			var manager = new THREE.LoadingManager( loadModel );

			manager.onProgress = function ( item, loaded, total ) {

				console.log( item, loaded, total );

			};

			// texture

			if( section.getAttribute('data-texture') ) {

				var textureLoader = new THREE.TextureLoader( manager );
				// var texture = textureLoader.load( window.location.origin + '/wp-content/themes/grinspire/assets/obj/plazma/plazma.png' );
				var texture = textureLoader.load( section.getAttribute('data-texture') );

			}

			// model

			function onProgress( xhr ) {

				if ( xhr.lengthComputable ) {

					var percentComplete = xhr.loaded / xhr.total * 100;
					console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );

				}

			}

			function onError() {}

			// var loader = new OBJLoader( manager );
			var loader = new FBXLoader( manager );

			loader.load( window.location.origin + '/wp-content/themes/grinspire/assets/fbx/mesh_plazma/Mesh_Plazma_23_05_2020.fbx', function ( obj ) {


				object = obj;


			}, onProgress, onError );

			//

			renderer = new THREE.WebGLRenderer({ antialias: true });
			renderer.setPixelRatio( window.devicePixelRatio );
			renderer.setSize( window.innerWidth, window.innerHeight );
			container.appendChild( renderer.domElement );

			document.addEventListener( 'mousemove', onDocumentMouseMove, false );

			//

			window.addEventListener( 'resize', onWindowResize, false );

		}

		function onWindowResize() {

			windowHalfX = window.innerWidth / 2;
			windowHalfY = window.innerHeight / 2;

			camera.aspect = window.innerWidth / window.innerHeight;
			camera.updateProjectionMatrix();

			renderer.setSize( window.innerWidth, window.innerHeight );

		}

		function onDocumentMouseMove( event ) {

			mouseX = ( event.clientX - windowHalfX ) / 250;
			mouseY = ( event.clientY - windowHalfY ) / 250;

		}

		//

		function animate() {

			object.rotation.x += 0.0001;
          	object.rotation.y += 0.002;

          	//console.log(object);

			requestAnimationFrame( animate );
			render();

		}

		function render() {

			camera.position.x += ( mouseX - camera.position.x ) * .05;
			camera.position.y += ( - mouseY - camera.position.y ) * .05;

			camera.lookAt( scene.position );

			renderer.render( scene, camera );

		}


	},
	finalize() {

	}

}