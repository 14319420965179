import cookie from '../Components/Cookie/Cookie';
import "intersection-observer";
import scrollama from "scrollama";
import sal from 'sal.js';
import $ from 'jquery';
import Facebook from '../Components/Facebook/Facebook';


export default {

	init() {

		cookie.init();
		Facebook.init();
		
		function mainMenu() {

			var menu = document.querySelector('.MainMenu');
			var menuContent = menu.querySelector('.mainMenu__content');
			var menuItems = menuContent.querySelectorAll('nav a');
			var hamburger = document.querySelector('.Hamburger');

			hamburger.addEventListener('click', function() {

				if( menu.classList.contains('active') ) {

					menu.classList.remove('active');
					hamburger.classList.remove('active');

				} else {

					menu.classList.add('active');
					hamburger.classList.add('active');

				}

			});

			menu.addEventListener('click', function() {

				if( menu.classList.contains('active') ) {

					menu.classList.remove('active');
					hamburger.classList.remove('active');

				}

			});

			menuContent.addEventListener('click', function(e) {

				e.stopPropagation();

			});

			menuItems.forEach( function( item,index ) {

				if( 
					!item.getAttribute('href').split('#')[0] && 
					item.getAttribute('href').split('#')[1] &&
					document.querySelector('section#'+item.getAttribute('href').split('#')[1])
				) {

					item.addEventListener('click', function() {

						menu.classList.remove('active');
						hamburger.classList.remove('active');
					    $("html, body").animate({ scrollTop: document.querySelector('section#'+item.getAttribute('href').split('#')[1]).offsetTop }, 600);

					});

				} else {

					item.addEventListener('click', function() {

						menu.classList.remove('active');
						hamburger.classList.remove('active');

					});

				}

			});

		}
		mainMenu();	
			


		function logoOnScroll() {

			var viewportElement = document.documentElement;

			var header = document.querySelector('header');
			var svg = header.querySelector('.header__home svg');
			var g = svg.querySelector('g');

			// console.log( svg.getBBox() );
			// console.log( g.getBBox() );

			function gNewPosition() {

				g.style.transform = 'translateY('+( g.getBBox().y - ( (svg.getBBox().height/2) + (g.getBBox().height/2) ) )+'px';

			}
			
			if( document.querySelector('main.Homepage') ) {

				window.addEventListener('scroll', function(e) {

					if( window.scrollY > 0 ) {

						gNewPosition();
						
					} else {

						g.style.transform = "translateY(0px)";

					}

				});

				window.addEventListener('resize', function() {

					if( window.scrollY > 0 ) {

						gNewPosition();

					}

				}); 

			} 

		}
		// logoOnScroll();



		function footerOnScroll() {

			var footer = document.querySelector('footer');
			var footerStep = footer.querySelector('.footer__step');

			const scrollerFooter = scrollama();

			scrollerFooter
				.setup({
					step: footerStep,
					offset: 0.99
				})
				.onStepEnter(response => {

					footer.classList.add('show');

				})
				.onStepExit(response => {

					footer.classList.remove('show');

				});

			// setup resize event
			window.addEventListener("resize", scrollerFooter.resize);

		}	
		footerOnScroll();


		function salAnimation() {

			// initial by class: sal-animate
			const salObject = new sal({
				disable: true,
			});
			var contents = document.querySelectorAll('.sal-animate');

			contents.forEach( function( content,index ) {

				content.setAttribute( "data-sal", "fade" );
				content.setAttribute( "data-sal-duration", "500" );
				content.setAttribute( "data-sal-easing", "ease-out-back" );
				content.setAttribute( "data-sal-delay", "300" );

			});

			salObject.reset({
				disable: false,
			});

		}
		salAnimation();


		function headerBackground() {

			var main = document.querySelector('main');

			if( main.classList.contains('Homepage') ) {

				var header = document.querySelector('header');
				var container = document.querySelector('main section');
				var containerHeight = container.offsetHeight - header.offsetHeight;

				window.addEventListener('scroll', function(e) {

					if( window.scrollY > containerHeight && !header.classList.contains('header--background') ) {

						header.classList.add('header--background');

					} else if( window.scrollY < containerHeight && header.classList.contains('header--background') ) {

						header.classList.remove('header--background');

					}

				});

				window.addEventListener('resize', function() {

					containerHeight = container.offsetHeight - header.offsetHeight;

				});

				if( window.scrollY > containerHeight ) {

					header.classList.add('header--background');

				}

			}

		}
		headerBackground();

	},
	cursors() {

		
		// -- Check if touch device -- //

			function is_touch_device() {  
				try {  
					document.createEvent("TouchEvent");  
					return true;  
				} catch (e) {  
					return false;  
				}  
			}

		// -- END -- //


		function pageCursors() {

			var cursors = document.querySelectorAll('.page__cursor')

			var pointBlack = document.querySelector('.point__black');
			var pointGreen = document.querySelector('.point__green');

			var arrowBlackDown = document.querySelector('.arrow__black__down');
			var arrowGreenRight = document.querySelector('.arrow__green__right');
			var arrowGreenLeft = document.querySelector('.arrow__green__left');

			var globalCursorX = 0;
			var globalCursorY = 0;

			document.addEventListener("mousemove", e => {
		    	globalCursorX = e.clientX;
		    	globalCursorY = e.clientY;
		    });



			// -- functions -- //

				function setPosition( cursor ) {

					cursor.style.top = globalCursorY+'px';
					cursor.style.left = globalCursorX+'px';

				}

				function mouseEnter( cursor ) {

					cursor.classList.add('active');
					setPosition(cursor);

				}

				function mouseMove( cursor ) {

					if( !cursor.classList.contains('active') ) {

						cursor.classList.add('active');

					}

					setPosition( cursor );

				}

				function mouseLeave( cursor ) {

					setPosition( cursor );
					cursor.classList.remove('active');

				}

				function disableCursors() {

					cursors.forEach( function(cursor,index) {

						cursor.classList.remove('active');

					});

				}

			// -- END -- //



			// -- PointBlack -- //

				var containerPointBlack = document.querySelectorAll('.cursor--point__black');

				document.addEventListener('mouseenter', function() {

					mouseEnter( pointBlack );

				});

				document.addEventListener('mouseleave', function() {

					mouseLeave( pointBlack );

				});

				if( containerPointBlack.length > 0 ) {

					containerPointBlack.forEach( function(box,index) {

						box.addEventListener('mouseenter', function() {

							mouseEnter(pointBlack);

						});

						box.addEventListener('mousemove', function() {

							if( pointBlack.classList.contains('active') ){ 

								mouseMove(pointBlack);

							}

						});

						// box.addEventListener('mouseleave', function() {

						// 	mouseLeave(pointBlack);
						// 	console.log('point black leave');

						// });

					});

				}

			// -- END -- //



			// -- PointGreen -- //

				var containerPointGreen = document.querySelectorAll('.cursor--point__green');

				if( containerPointGreen.length > 0 ) {

					containerPointGreen.forEach( function(box,index) {

						box.addEventListener('mouseenter', function() {

							mouseLeave(pointBlack);
							mouseEnter(pointGreen);

						});

						box.addEventListener('mousemove', function() {

							mouseMove(pointGreen);

						});

						box.addEventListener('mouseleave', function() {

							mouseLeave(pointGreen);
							mouseEnter(pointBlack);

						});

					});

				}

			// -- END -- //


			// -- Cookie on click -- // 

				var cookieContainer = document.querySelector('.Cookie');

				if( cookieContainer ) {

					var cookieAccept = cookieContainer.querySelector('.cookie__accept');
					var cookieClose = cookieContainer.querySelector('.cookie__close');

					cookieAccept.addEventListener('click', function() {

						mouseLeave(pointGreen);
						mouseEnter(pointBlack);

					});

					cookieClose.addEventListener('click', function() {

						mouseLeave(pointGreen);
						mouseEnter(pointBlack);

					});

				}

			// -- END -- //


			// -- ArrorGreenLeft / Right -- //

				var projectsImages = document.querySelectorAll('.Projects .single__thumbnail');

				if( projectsImages.length > 0 ) {

					projectsImages.forEach( function( image,index ) {

						image.addEventListener('mouseenter', function() {

							mouseLeave(pointBlack);

							if( image.classList.contains('cursor--arrow__green__left') ) {

								mouseEnter(arrowGreenLeft);

							} else if( image.classList.contains('cursor--arrow__green__right') ) {

								mouseEnter(arrowGreenRight);

							}

						});

						image.addEventListener('mousemove', function() {

							if( image.classList.contains('cursor--arrow__green__left') ) {

								mouseMove(arrowGreenLeft);

							} else if( image.classList.contains('cursor--arrow__green__right') ) {

								mouseMove(arrowGreenRight);

							}

						});

						image.addEventListener('mouseleave', function() {

							if( image.classList.contains('cursor--arrow__green__left') ) {

								mouseLeave(arrowGreenLeft);

							} else if( image.classList.contains('cursor--arrow__green__right') ) {

								mouseLeave(arrowGreenRight);

							}
							disableCursors();

							mouseEnter(pointBlack);

						});

					});

				}

			// -- END -- //



			// -- ArrowBlackDown -- // 

				var homeHead = document.querySelector('.HomeHead');

				if( homeHead ) {

					homeHead.addEventListener('mouseenter', function(e) {

						mouseLeave(pointBlack);	
						mouseEnter(arrowBlackDown);

					});

					homeHead.addEventListener('mousemove', function(e) {

						mouseMove(arrowBlackDown);

					});

					homeHead.addEventListener('mouseleave', function(e) {

						mouseLeave(arrowBlackDown);
						mouseEnter(pointBlack);

					});

				}

			// -- END -- //
			
		}
		if( !is_touch_device() ) {
			pageCursors();
		}

	},
	finalize() {

	}

}