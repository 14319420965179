import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.Projects');

		if( container ) {

			var carousel = container.querySelector('.row__slick');
			var carouselItems = container.querySelectorAll('.slick__single');
			var carouselCurrentItem = 0;
			var buttonNext = container.querySelector('.row__button--next');
			var buttonPrev = container.querySelector('.row__button--prev');

			$(carousel).slick({
				slidesToShow: 2,
				slidesToScroll: 1,
				arrows: false,
				infinite: false,
				rows: 0,
				responsive: [
					{
						breakpoint: 701,
						settings: {
							slidesToShow: 1.1,
						}
					}
				]
			});		

			buttonNext.addEventListener('click', function() {

				$(carousel).slick('slickNext');

			});

			buttonPrev.addEventListener('click', function() {

				$(carousel).slick('slickPrev');

			});

			$(carousel).on('beforeChange', function(event, slick, currentSlide, nextSlide ) {
				

				if( (nextSlide+1) == (carouselItems.length-1) ) {

					buttonNext.classList.add('hide');

				} else if( nextSlide == 0 ) {

					buttonPrev.classList.add('hide');

				} else {

					buttonNext.classList.remove('hide');
					buttonPrev.classList.remove('hide');

				}

			});


		}

	},
	finalize() {

	}

}