export default {

	init() {

		function startScript() {

			window.fbAsyncInit = function() {	
				FB.init({
					xfbml            : true,
					version          : 'v7.0'
				});
			};

			(function(d, s, id) {
				var js, fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) return;
				js = d.createElement(s); js.id = id;
				js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
				fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));

		}



		if(document.readyState === 'complete' && document.querySelector('#fb-root') ) {

	    	startScript();

	    } else {

	        document.addEventListener('readystatechange', function(e) {

	            if( e.target.readyState === 'complete' && document.querySelector('#fb-root') ) {
	                startScript();
	            }

	        });

	    }

		

	},
	finalize() {

	}

}