import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.Quotations');

		if( container ) {

			var carousel = container.querySelector('.content');
			var switchButton = container.querySelectorAll('.points__single');

			$(carousel).slick({
				slidesToShow: 1,
				arrows: false,
				fade: true,
  				cssEase: 'linear',
  				infinite: true,
  				adaptiveHeight: true
			});

			switchButton.forEach( function( button,index ) {

				button.addEventListener('click', function() {

					switchButton.forEach( function( button2, index2 ) {

						button2.classList.remove('active');

					});

					button.classList.add('active');
					$(carousel).slick('slickGoTo',index);

				});

			});

			$(carousel).on('beforeChange', function(event, slick, currentSlide, nextSlide) {

				switchButton[currentSlide].classList.remove('active');
				switchButton[nextSlide].classList.add('active');
				
			});

		}

	},
	finalize() {

	}

}